var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guard-log" },
    [
      _c(
        "app-list",
        { ref: "list", attrs: { opt: _vm.opt }, on: { get: _vm.onGet } },
        [
          _vm.$route.query.rzjId
            ? _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-row",
                    { staticClass: "info" },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("span", { staticClass: "txt" }, [
                          _vm._v("门禁信息")
                        ]),
                        _c("span", { staticClass: "txt" }, [
                          _vm._v("设备SN: " + _vm._s(_vm.row.deviceId))
                        ]),
                        _c("span", { staticClass: "txt" }, [
                          _vm._v("门禁位置: " + _vm._s(_vm.row.area))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="guard-log">
    <app-list :opt="opt" @get="onGet" ref="list">
      <template slot="header" v-if="$route.query.rzjId">
        <el-row class="info">
          <el-col :span="24">
            <span class="txt">门禁信息</span>
            <span class="txt">设备SN: {{row.deviceId}}</span>
            <!-- <span class="txt">门禁控制编号:</span> -->
            <span class="txt">门禁位置: {{row.area}}</span>
          </el-col>
        </el-row>
      </template>
    </app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      id: "",
      opt: {
        title: "门禁记录",
        isShowBack: true,
        search: [
          {
            key: "date",
            label: "起止日期",
            type: "daterange",
            timeType: "yyyy-MM-dd"
          },
          {
            key: "unlockMethod",
            label: "开门方式",
            type: "select",
            opt: {
              list: [
                {
                  value: 3,
                  label: "人脸识别开门"
                },
                {
                  value: 4,
                  label: "刷身份证开门"
                }
              ]
            }
          },
          {
            key: "name",
            label: "开门人姓名"
          }
        ],
        columns: [
          // { label: "序号", key: "sequenceNumber", width: 80 },
          {
            label: "开门方式",
            key: "unlockMethod",
            on: row => {
              _this.$router.push("/main/guard/detail?id="+ row.id);
              //  _this.$router.push("/main/guard/detail/");
            }
          },
          { label: "房源名称", key: "apartmentName" },
          { label: "开门人类型", key: "userTypeStr" },
          { label: "开门人姓名", key: "name" },
          { label: "开门人性别", key: "gender" },
          { label: "开门时间", key: "unlockDate" },
          { label: "开门结果", key: "unlockStateStr" },
          {
            label: "操作",
            key: "recognizePicUrl",
            type: "action",
            opt: {
              list: [
                {
                  label: "查看照片",
                  on(row) {
                    _this.$alert(
                      `<img src="${row.recognizePicUrl}">`,
                      "查看人脸识别开门照片",
                      {
                        dangerouslyUseHTMLString: true,
                        callback: action => {}
                      }
                    );
                  }
                }
              ]
            }
          }
        ]
      },
      row: {},
      id: ""
    };
  },
  activated() {
    if (this.$route.query.rzjId) {
      this.getDeviceInfo();
    }
    console.log("lock-log activated!!");
  },
  methods: {
    onGet(opt) {
      console.log(opt.searchForm);
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        startDate: opt.searchForm["date"] ? opt.searchForm["date"][0] : null,
        endDate: opt.searchForm["date"] ? opt.searchForm["date"][1] : null,
        name: opt.searchForm["name"] ? opt.searchForm["name"] : null,
        rzjId: this.$route.query.rzjId ? this.$route.query.rzjId : null,
        unlockMethod: opt.searchForm["unlockMethod"]
          ? opt.searchForm["unlockMethod"]
          : null
      };
      this.post("/device-service/rzjmanagment/getRzjEntryRecordList", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.unlockMethod = [" "," ","人脸识别","身份证识别","远程开门"][item.unlockMethod-1];
          item.gender = ["男", "女"][item.gender - 1];
          item.userTypeStr = [
            "系统管理员",
            "房东",
            "工程人员",
            "财务",
            "房客",
            "房管员",
            "",
            "",
            "",
            "未知人员"
          ][item.userType - 1];
          item.unlockStateStr = ["成功", "失败"][item.unlockState - 1];
          // item.date = this.format(item.createdDate);
        }
        opt.cb(res.data);
      });
    },
    getDeviceInfo() {
      let dto = {
        rzjId: this.$route.query.rzjId
      };
      this.post("device-service/rzjmanagment/queryRzjDeviceInfo", dto).then(
        data => {
          this.row = data;
        }
      );
    }
  }
};
</script>
<style lang="scss" scope>
.el-message-box {
  width: 780px;
  p {
    width: 100%;
  }
  img {
    width: 100%;
  }
}

.guard-log {
  .danger {
    color: #f56c6c;
  }

  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      margin-right: 20px;
    }
  }
}
</style>
